import React from 'react';
import ArticleDetail from '../../components/if-magazine/detail';
import Layout from '../../modules/layout';

const ArticlePage = ({ pageContext }) => {
  const { seoTitle, seoDescription, previewImage, slug } = pageContext;
  return (
    <Layout
      title={seoTitle}
      description={seoDescription}
      previewImage={
        pageContext.images?.[0]?.image?.file?.url || previewImage?.file?.url
      }
      isFooterBeige
      slug={slug}
    >
      <ArticleDetail data={pageContext} />
    </Layout>
  );
};

export default ArticlePage;
