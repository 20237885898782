/* Global imports */
import React from 'react';
import pt from 'prop-types';
import Link from '../../../link';
import Icon from '../../../icon';
import { getSocialShareUrl } from '../../../../../helpers/getSocialShareUrl';
import * as styles from '../../styles.module.scss';

const ArticleDetailSocials = ({ shareURL }) => {
  return (
    <>
      <Link link={getSocialShareUrl('facebook', shareURL)}>
        <Icon name="facebook" type="fab" size="lg" className={styles.link} />
      </Link>
      <Link link={getSocialShareUrl('linkedin', shareURL)}>
        <Icon name="linkedin" type="fab" size="lg" className={styles.link} />
      </Link>
      <Link link={getSocialShareUrl('twitter', shareURL)}>
        <Icon name="x-twitter" type="fab" size="lg" className={styles.link} />
      </Link>
    </>
  );
};

ArticleDetailSocials.propTypes = {
  shareURL: pt.string,
};
ArticleDetailSocials.defaultProps = {
  shareURL: '',
};

export default ArticleDetailSocials;
