/* Global imports */
import React from 'react';
import pt from 'prop-types';
import Link from '../../../link';
import Icon from '../../../icon';
import { getSocialShareUrl } from '../../../../../helpers/getSocialShareUrl';
import * as styles from '../../styles.module.scss';
import { copyToClipboard } from '../../../../../helpers/copyToClipboard';

const DefaultSocials = ({ shareURL }) => {
  return (
    <>
      <Link link={getSocialShareUrl('facebook', shareURL)}>
        <Icon name="facebook" type="fab" size="lg" className={styles.link} />
      </Link>
      <Link link={getSocialShareUrl('whatsapp', shareURL)}>
        <Icon
          name="whatsapp-square"
          type="fab"
          size="lg"
          className={styles.link}
        />
      </Link>
      <Link link={getSocialShareUrl('twitter', shareURL)}>
        <Icon name="x-twitter" type="fab" size="lg" className={styles.link} />
      </Link>
      <>
        <Link link={getSocialShareUrl('mail', shareURL)}>
          <Icon name="envelope" type="fal" size="lg" className={styles.link} />
        </Link>
        <Icon
          name="link"
          type="fal"
          size="lg"
          className={styles.link}
          onClick={() => copyToClipboard(shareURL)}
        />
      </>
    </>
  );
};

DefaultSocials.propTypes = {
  shareURL: pt.string,
};
DefaultSocials.defaultProps = {
  shareURL: '',
};

export default DefaultSocials;
