import React, { useContext } from 'react';
import pt from 'prop-types';
import Text from '../../../../ui/text';
import Image, { imageProptypes } from '../../../../ui/image';
import SocialShare from '../../../../ui/socialShare';
import { combine } from '../../../../../helpers/styles';
import Container from '../../../../../modules/container';
import { getMagazineArticleLink } from '../../../../../helpers/getMagazineArticleLink';
import CustomScrollable from '../../../../ui/customScrollable';
import * as styles from './styles.module.scss';
import { MicrocopyContext } from '../../../../../context/microcopy.context';
import { useLocale } from '../../../../../context/locale.context';
import { getCopy } from '../../../../../helpers/copy';
import { getFormattedDate } from '../../../../../helpers/getFormattedDate';

const ArticleHeader = ({
  articleType,
  createdAt,
  readingTime,
  headline,
  subline,
  images,
  slug,
}) => {
  const microcopy = useContext(MicrocopyContext);
  const { locale } = useLocale();
  const createdDate = getFormattedDate(createdAt, 'MMM yyyy', locale);

  const renderImage = (image, index) => (
    <div key={index}>
      <Image
        image={image.image}
        imageCaption={image.imageCaption}
        className={styles.img}
        focusPoint={image.focusPoint}
      />
    </div>
  );

  return (
    <Container fullWidth>
      <Container fluid small hasWhiteBg innerClass={styles.header}>
        <div className={styles.metaWrapper}>
          <Text
            as="span"
            type="infotext"
            variant="condensed"
            className={styles.readingTime}
          >
            {getCopy('label.readingTime', microcopy)} {readingTime} min |{' '}
            {createdDate}
          </Text>
          <SocialShare
            displayLabel
            shareURL={`${process.env.GATSBY_DOMAIN}/${getMagazineArticleLink(
              slug
            )}`}
            isArticleDetail
          />
        </div>
        <div className={styles.textWrapper}>
          {articleType && (
            <Text as="span" type="articleType" className={styles.type}>
              {articleType}
            </Text>
          )}
          {headline && (
            <Text as="h1" type="h1" variant="editorial" className={styles.hl}>
              {headline}
            </Text>
          )}
          {subline && (
            <Text as="h2" type="h3">
              {subline?.subline ? subline.subline : subline}
            </Text>
          )}
        </div>
        {images && (
          <div
            className={combine(
              styles.imageWrapper,
              images.length === 1 && styles.singleImage,
              images.length > 2 && styles.hasSlider
            )}
          >
            {images.length > 2 ? (
              <CustomScrollable>
                {images.map((image, index) => renderImage(image, index))}
              </CustomScrollable>
            ) : (
              <>{images.map((image, index) => renderImage(image, index))}</>
            )}
          </div>
        )}
      </Container>
    </Container>
  );
};

ArticleHeader.prototype = {
  articleType: pt.string,
  createdAt: pt.string,
  readingTime: pt.number,
  headline: pt.string,
  subline: pt.string,
  images: pt.arrayOf(pt.shape(imageProptypes)),
  slug: pt.string.isRequired,
};

ArticleHeader.defaultProps = {
  articleType: '',
  createdAt: '',
  readingTime: 0,
  headline: '',
  subline: '',
  images: [],
};

export default ArticleHeader;
