// extracted by mini-css-extract-plugin
export var hasSlider = "styles-module--hasSlider--8a092";
export var header = "styles-module--header--b83e2";
export var hl = "styles-module--hl--ba56b";
export var imageWrapper = "styles-module--imageWrapper--ff186";
export var img = "styles-module--img--dbe1d";
export var metaWrapper = "styles-module--metaWrapper--06a41";
export var readingTime = "styles-module--readingTime--691d1";
export var singleImage = "styles-module--singleImage--a7b67";
export var textWrapper = "styles-module--textWrapper--a0ace";
export var type = "styles-module--type--4a08c";